import { useEffect, useState } from "react";
import { registerLog } from "libs/analytics";
import MainLayout from "views/layout/MainLayout";
import SliderCarousel from "components/SliderCarousel";
import BackgroundVideo from "components/VideoPlayer";
import { Solutions } from "views/containers/Services/Business/Solutions/solutions";
import { RelatedProducts } from "views/containers/Services/Business/RelatedProducts/related-products";
import { getProducts } from "services/products/get-products-services";
import { getMostDemandedServices } from "services/services";
import { Container } from "./styled";
import FormInquiryLogistic from "views/containers/Forms/Services/Logist/form-inquiry-logistic/form-inquiry-logistic";
import SimpleImageBanner from "ui/simple-image-banner";
import { Banner } from "views/containers/Services/Business/Banner/banner";
import { BREAKPOINTS, CATEGORIES_SERVICES_IDS } from "constants/index";
import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
import { docDataBusinessMock } from "__mocks__/servicesMock";
import { Extras } from "modules/services/components/Extras/extras";
import { useSelector } from "react-redux";
import { getLanguage } from "redux/common";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

const BusinessSubService = () => {
  const language = useSelector(getLanguage);

  const [services, setServices] = useState<any[]>([]);
  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const [relatedProducts, setRelatedProducts] = useState<any[]>([]);

  const { collectionData: banners = [] } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/International Business/Banners",
  });

  const { collectionData: solutions = [] } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/International Business/Posts",
    filters: [
      {
        field: "type",
        operator: "==",
        value: "solution",
      },
    ],
  });

  const { collectionData: subServices = [] } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/International Business/Posts",
    filters: [
      {
        field: "type",
        operator: "==",
        value: "service",
      },
    ],
  });

  useEffect(() => {
    // Registro de log
    registerLog("International business screen", {
      page_title: "International business",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    // Obtiene productos relacionados
    getProducts(
      { path: "PANAMA/Products/Categories/Store/Posts" },
      CATEGORIES_SERVICES_IDS.machinery
    ).then((res) => {
      setRelatedProducts(res);
    });

    // Obtiene servicios más demandados si no se han cargado
    if (services.length === 0) {
      getMostDemandedServices().then((res) => {
        setServices(res);
      });
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];

      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          if (doc.imagesMobile?.[language]?.image) {
            listBanners.push(doc.imagesMobile?.es?.image);
          }
        } else {
          if (doc.imagesDesktop?.[language]?.image) {
            listBanners.push(doc.imagesDesktop?.es?.image);
          }
        }
      });

      setBannerImages(listBanners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banners]);

  const firstSolutions = solutions?.slice(0, 3) || [];
  const secondSolutions = solutions?.slice(3) || [];

  return (
    <MainLayout type="services">
      <Container>
        <BackgroundVideo
          showTitle={false}
          volume={0}
          url="https://firebasestorage.googleapis.com/v0/b/wdyn7-master-cloud-storage/o/countries%2FPANAMA%2Fvideos%2Fvideo-logistica-panama.mp4?alt=media&token=b3dca5f1-f00c-4e09-bb6e-0917572eeba7"
          playing={true}
          Form={FormInquiryLogistic}
          stylePlayerContainer={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
        <Banner />
        <Solutions solutions={firstSolutions as any} />
        <SliderCarousel images={bannerImages} />
        <Solutions solutions={secondSolutions as any} />
        <SliderCarousel images={bannerImages} />
        <Extras data={subServices} hasRedirectToDetail />
        <SimpleImageBanner
          hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+piezas+de+autos.&type=phone_number&app_absent=0"
          imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FPiezas%20de%20autos%20repuestos%20para%20todo%20tipo%20de%20carros.webp?alt=media&token=0bb170ec-e9c5-498e-a751-2260fd8f9ebe"
        />
        <RelatedProducts data={relatedProducts || []} />
        {window.innerWidth < BREAKPOINTS.lg && (
          <BannerFooterMobile
            data={docDataBusinessMock}
            secondaryText="Contáctanos y recibe atención personalizada gratis"
            text="¿No encuentras lo que buscas?"
            isShowImg={false}
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default BusinessSubService;
