import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "views/layout/MainLayout/main-layout";
import { Container, HeaderContent } from "./styled";
import ContentProductsFiltered from "views/containers/Products/ContentProductsFiltered";
import ContentProductsByCategory from "views/containers/Products/ContentProductsByCategory";
import { BackButton } from "ui/Buttons";
import useCategories from "hooks/useGetCategories/useGetCategories";
import { useGetFirestoreDoc } from "hooks/useGetDocumentFirestore";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
import { Product, DBCountryReference, FiltersProducts } from "types";
import { DB_ROUTES } from "constants/routes";
import { buildQueryFilterProducts } from "../main";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import firebase from "firebase/compat/app";

const { CATEGORIES_PRODUCTS_STORE, POSTS_PRODUCTS_STORE } = DB_ROUTES;

const ProductsByCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const registerLog = useAnalytics();

  const { categories } = useCategories(
    `${DBCountryReference.PAN}${CATEGORIES_PRODUCTS_STORE}`
  );
  const { docData: categoryInfo, fetchDoc: fetchCategoryInfo } =
    useGetFirestoreDoc();

  const [categoryUid, setCategoryUid] = useState<string>("");
  const [filters, setFilters] = useState<FiltersProducts>({});

  // Obtener el uid de la categoría desde el query param
  useEffect(() => {
    const uidCategory = new URLSearchParams(location.search).get("uid");
    fetchCategoryInfo({
      path: `${DBCountryReference.PAN}${CATEGORIES_PRODUCTS_STORE}`,
      uid: uidCategory || "",
    });
    setCategoryUid(uidCategory || "");

    registerLog({
      screenName: `Products by category ${uidCategory}`,
      pageTitle: `Products by category ${uidCategory} of whatdoyouneed7.com`,
      pagePath: window.location.pathname,
      pageLocation: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryUid, fetchCategoryInfo]);

  useEffect(() => {
    if (categoryInfo) {
      setFilters({ categoryId: categoryInfo.id });
    }
  }, [categoryInfo]);

  const queryData = buildQueryFilterProducts(filters);

  const filtersMapped = queryData.query.map((f) => ({
    ...f,
    operator: f.operator as firebase.firestore.WhereFilterOp,
  }));

  const { collectionData: productsByCategory = [] } =
    useGetFirestoreCollectionQuery({
      path: `${DBCountryReference.PAN}${POSTS_PRODUCTS_STORE}`,
      filters: categoryInfo
        ? [
            {
              field: "listIdCategory",
              operator: "array-contains-any",
              value: [categoryInfo.id],
            },
          ]
        : [],
      enabled: !!categoryInfo,
    });

  const { collectionData: productsByFilters = [] } =
    useGetFirestoreCollectionQuery({
      path: `${DBCountryReference.PAN}${POSTS_PRODUCTS_STORE}`,
      filters: filtersMapped,
      orderFilter: queryData.orderBy,
      enabled: !!queryData.query && queryData.query.length > 0, // Ejecutar solo si hay filtros
    });

  const handleSelectCategory = (category: string) => {
    navigate(`/products/category?uid=${category}`);
  };

  const handleSetFilters = (filters: FiltersProducts) => {
    setFilters(filters);
  };

  return (
    <MainLayout type="products">
      <HeaderContent>
        <BackButton onClick={() => navigate(-1)} />
      </HeaderContent>
      <Container>
        {productsByFilters && productsByFilters.length > 0 ? (
          <ContentProductsFiltered
            data={productsByFilters as any}
            categories={categories}
            setFilters={handleSetFilters}
            filters={filters}
          />
        ) : (
          <ContentProductsByCategory
            setFilters={handleSetFilters}
            categories={categories}
            data={productsByCategory as Product[]}
            filters={filters}
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default ProductsByCategory;
