import { useEffect, useState } from "react";
import MainLayout from "views/layout/MainLayout";
import { Container, VideoPlayerContainer } from "./styled";
import { Banner } from "./components/banner/banner";
import VideoPlayer from "components/VideoPlayer";
import ListYachts from "./components/list-yachts";
import SliderCarousel from "components/SliderCarousel";
import FormInquiryYachts from "views/containers/Forms/Services/Yachts/form-inquiry-yachts";
import ExtraServicesYachts from "./components/Extras/extras-services-container";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
import {
  BANNERS_PATH,
  DESTINATIONS_PATH,
  SERVICES_PATH,
  YACHT_VIDEO_URL,
} from "./constants";
import FooterBanner from "./components/footer-banner";
import { BREAKPOINTS } from "constants/index";
import { TOURS_PATH } from "../tourism/constants";
import { useSelector } from "react-redux";
import { currentYachtNameSelector } from "redux/user";
import SimpleImageBanner from "ui/simple-image-banner";
import { useTranslation } from "react-i18next";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore"; // Nuevo hook con react-query

const YachtService = () => {
  const { t } = useTranslation();
  const registerLog = useAnalytics();
  const currentYachtName = useSelector(currentYachtNameSelector);
  const [bannerImages, setBannerImages] = useState<string[]>([]);

  // Registro analítico al montar el componente
  useEffect(() => {
    registerLog({
      screenName: "Main Yachts screen",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
      pageTitle: "Main Yatch services screen",
    });
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Consultas a Firestore usando el hook con react-query
  const {
    collectionData: banners = [],
    loadingGetFirestoreCollection: loadingBanners,
    errorGetFirestoreCollection: errorBanners,
  } = useGetFirestoreCollectionQuery({
    path: BANNERS_PATH,
  });

  const {
    collectionData: listAvailableYachts = [],
    loadingGetFirestoreCollection: loadingYachts,
    errorGetFirestoreCollection: errorYachts,
  } = useGetFirestoreCollectionQuery({
    path: SERVICES_PATH,
    filters: [
      {
        field: "idCategory",
        operator: "==",
        value: 205,
      },
    ],
  });

  const {
    collectionData: destinationsList = [],
    loadingGetFirestoreCollection: loadingDestinations,
    errorGetFirestoreCollection: errorDestinations,
  } = useGetFirestoreCollectionQuery({
    path: DESTINATIONS_PATH,
  });

  const {
    collectionData: extraServicesData = [],
    loadingGetFirestoreCollection: loadingExtraServices,
    errorGetFirestoreCollection: errorExtraServices,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/Yatchs/ExtraServices",
  });

  const {
    collectionData: toursList = [],
    loadingGetFirestoreCollection: loadingTours,
    errorGetFirestoreCollection: errorTours,
  } = useGetFirestoreCollectionQuery({
    path: TOURS_PATH,
  });

  // Función auxiliar para dividir el array de Yates
  const processArray = (arr: any[], deleteFirstHalf: boolean) => {
    const arrLength = arr?.length || 0;
    const halfLength = Math.floor(arrLength / 2);

    if (arrLength > 0) {
      if (deleteFirstHalf) {
        arr.splice(0, halfLength);
      } else {
        arr.splice(halfLength);
      }
    }

    return arr;
  };

  const listForFirstYachts = [...(listAvailableYachts || [])];
  const listForLastYachts = [...(listAvailableYachts || [])];

  listForFirstYachts.sort((a: any, b: any) => a.priority - b.priority);
  listForLastYachts.sort((a: any, b: any) => a.priority - b.priority);

  const firstYachtsList = processArray(listForFirstYachts, false);
  const lastYachtsList = processArray(listForLastYachts, true);

  const currentYacht = firstYachtsList
    .concat(lastYachtsList)
    .find((yacht) => yacht.name.es === currentYachtName);

  // Actualiza las imágenes del banner cuando cambian los banners o el yate actual
  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];
      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners, currentYacht]);

  // Opcional: Puedes agregar una lógica global de loading mientras cargan las colecciones.
  const isLoading =
    loadingBanners ||
    loadingYachts ||
    loadingDestinations ||
    loadingExtraServices ||
    loadingTours;

  // Opcional: Manejo de errores global
  const hasError =
    errorBanners ||
    errorYachts ||
    errorDestinations ||
    errorExtraServices ||
    errorTours;

  if (isLoading) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("loading")}</div>
        </Container>
      </MainLayout>
    );
  }

  if (hasError) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("error_loading_services")}</div>
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout type="services">
      <Container>
        <VideoPlayerContainer>
          <VideoPlayer
            volume={0}
            url={YACHT_VIDEO_URL}
            playing={true}
            Form={FormInquiryYachts}
            stylePlayerContainer={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </VideoPlayerContainer>
        <Banner />
        <ListYachts yachts={firstYachtsList || []} />
        <ExtraServicesYachts
          data={destinationsList}
          title={t("get_to_know_our_destinations")}
          backgroundColor="#FFFFFF"
          hasLink={false}
          path="services/tourism"
        />
        <ListYachts yachts={lastYachtsList || []} hideTitle />
        <ExtraServicesYachts
          data={extraServicesData}
          title={t("extra_services")}
          backgroundColor="#E7EDF5"
          hasLink={false}
          path="services/yatchs"
          type="extraServices"
        />
        <SimpleImageBanner
          hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+piezas+de+autos.&type=phone_number&app_absent=0"
          imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FPiezas%20de%20autos%20repuestos%20para%20todo%20tipo%20de%20carros.webp?alt=media&token=0bb170ec-e9c5-498e-a751-2260fd8f9ebe"
        />
        <ExtraServicesYachts
          data={toursList}
          title={t("you_might_also_be_interested_in_these_tours")}
          backgroundColor="#E7F7F6"
          hasLink={true}
          path="services/tourism"
          type="destinations"
        />
        {window.innerWidth > BREAKPOINTS.lg && (
          <SliderCarousel images={bannerImages} />
        )}
        {window.innerWidth < BREAKPOINTS.lg && <FooterBanner />}
      </Container>
      {/* Aquí podrías mostrar un loader modal si quisieras, depende de tu UI */}
      {/* <ModalLoader isLoading={isLoading} /> */}
    </MainLayout>
  );
};

export default YachtService;
