import { useEffect, useState } from "react";
import { useOptionsFormRealState } from "./constants";
import { registerLog } from "libs/analytics";
import MainLayout from "views/layout/MainLayout";
import { Container } from "./styled";
import SliderCarousel from "components/SliderCarousel";
import ServicesForm from "components/Forms/services-form";
import { Extras } from "../../components/Extras/extras";
import { Properties } from "modules/services/components/Properties/properties";
import { useTranslation } from "react-i18next";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

const RealState = () => {
  const { t } = useTranslation();
  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const optionsFormRealState = useOptionsFormRealState();

  const {
    collectionData: banners = [],
    loadingGetFirestoreCollection: loadingBanners,
    errorGetFirestoreCollection: errorBanners,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/RealState/Banners",
  });

  const {
    collectionData: technicalServiceData = [],
    loadingGetFirestoreCollection: loadingTechnical,
    errorGetFirestoreCollection: errorTechnical,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/TechnicalServices/Posts",
  });

  const {
    collectionData: properties = [],
    loadingGetFirestoreCollection: loadingProperties,
    errorGetFirestoreCollection: errorProperties,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/RealState/Posts",
    filters: [
      {
        field: "active",
        operator: "==",
        value: true,
      },
    ],
  });

  useEffect(() => {
    registerLog("Services main real state", {
      page_title: "Service main real state",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];
      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners]);

  const isLoading = loadingBanners || loadingTechnical || loadingProperties;
  const hasError = errorBanners || errorTechnical || errorProperties;

  if (isLoading) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("loading")}</div>
        </Container>
      </MainLayout>
    );
  }

  if (hasError) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("error_loading_services")}</div>
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout type="services">
      <Container>
        <SliderCarousel images={bannerImages} />
        <ServicesForm
          category="RealState"
          titleFormDescription={t("do_you_have_a_real_estate_need")}
          descriptionForm={t(
            "at_whatdoyouneed7_we_understand_that_each_person_has_unique_needs_in_the_real_estate_field"
          )}
          options={optionsFormRealState}
        />
        <Properties properties={properties} />
        <Extras data={technicalServiceData} hasRedirectToDetail hasLink />
      </Container>
    </MainLayout>
  );
};

export default RealState;
