import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNotification } from "libs/context/AlertContext";
import { MdCheckCircle } from "react-icons/md";
import Counter from "components/Counter";
import "./styles.css";
// components
import {
  Availability,
  TextH3,
  Container,
  ContentProductsContainer,
  GalleryContainer,
  General,
  GeneralText,
  GeneralTitle,
  Header,
  ItemContainer,
  Name,
  Ratings,
  DescriptionContainer,
  // Review,
  // ReviewDesc,
  // ReviewHeader,
  // ReviewLocation,
  // ReviewName,
  // ReviewOption,
  // ReviewProfile,
  // ReviewRating,
  // Reviews,
  SKU,
  SpaceBetween,
  SpanReview,
  BuyButtonsContainer,
  BuyButtons,
  ImagesContainer,
  TextCounter,
  CounterContainer,
  ButtonGoToCart,
  CounterAndText,
  ShippingDaysContainer,
  FeatureContainer,
  FeatureLabel,
  FeatureValue,
} from "./styled";
import { StrokedButton, BackButton, Button } from "ui/Buttons";
import WhatsAppButton from "components/ButtonWhatsapp/button-whatsapp";
import MediaGallery from "components/media-gallery";
import ModalDeleteConfirm from "modules/products/Details/components/modal-delete-confirm";
import ImageThumbnails from "./ImageThumbnails/image-thumbnails";
import PricesDetailProduct from "./Prices/prices-detail-product";
import Rating from "components/Rating";
import { useSelector } from "react-redux";
import useAddToCart from "hooks/cart/useAddToCart";
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import useProductInCartCheck from "hooks/cart/useProductInCartCheck";
import useGetProductInCart from "hooks/cart/useGetProductInCart";
import { useTranslation } from "react-i18next";
import useIsLocalhost from "hooks/system/useIsLocalhost/use-is-localhost";
import useDeleteCartItem from "hooks/cart/useDeleteCartItem";
import { cleanCart } from "redux/cart/reducer/reducer-cart";
import { getCountry, getLanguage } from "redux/common";
import { updateCartItemSagasTrigger } from "redux/cart/reducer/reducer-cart";
import { Product } from "types";
// import { P } from "ui/Text";
import { parseCurrencyToSymbol } from "utils/common";
import { LOGISTIC_PANAMA_PHONE_NUMBER } from "constants/constants";
import { MAIN_ROUTES } from "constants/routes";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import parse from "utils/parseHtmlToText";

interface Props {
  data: Product;
}
/**
 * @description this component show the details of a product
 */
const DetailsProduct = (props: Props) => {
  const { data } = props;
  const { mainProductCategory } = data;
  const { t } = useTranslation();
  const addToCart = useAddToCart();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isProductInCart = useProductInCartCheck();
  const productInCart = useGetProductInCart();
  const { showWarning } = useNotification();
  const isAuth = useIsAuth();
  const isLocalhost = useIsLocalhost();
  const deleteCartItem = useDeleteCartItem();
  const country = useSelector(getCountry);
  const language = useSelector(getLanguage);

  const [indexMainImageSelected, setIndexMainImageSelected] =
    useState<number>(0);
  const [detailCurrentProductInCart, setDetailCurrentProductInCart] =
    useState<any>(null);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

  const isProductAddedInCart = isProductInCart(props.data.id);

  const handleAddToCart = () => {
    if (isAuth) {
      addToCart(data as any);
    } else {
      showWarning(t("login_to_add_to_cart"));
    }
  };

  const handleAddToCartAndNavigate = () => {
    addToCart(data as any);
    navigate(MAIN_ROUTES.Cart);
  };

  const handleUpdateQuantity = (idItem: string | number, quantity: number) => {
    if (quantity < 1) {
      setShowModalDelete(true);
      return;
    }

    dispatch(updateCartItemSagasTrigger.run({ id: idItem, count: quantity }));
  };

  const handleBuyNow = () => {
    dispatch(cleanCart());
    setTimeout(() => {
      navigate(
        `${MAIN_ROUTES.CheckoutSingleProduct}?uid=${data.id}&mainProductCategory=${data.mainProductCategory}&country=${country}`
      );
    }, 500);
  };

  const handleDeleteFromCart = () => {
    setShowModalDelete(false);
    deleteCartItem({});
    // dispatch(deleteCartItemSagasTrigger.run({ id: detailCurrentProductInCart?.id }));
  };

  useEffect(() => {
    // add product to cart
    setDetailCurrentProductInCart(productInCart(props.data.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInCart]);

  const videoList = data?.videos || [];
  const allMediaList = [...videoList, ...data.images];

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} text="none" />
      <Name href="/product">{data.name[language]}</Name>
      <ContentProductsContainer>
        <ImagesContainer>
          <GalleryContainer>
            <MediaGallery
              data={data?.images || []}
              currentSelected={indexMainImageSelected}
            />
          </GalleryContainer>
          <TextH3>{t("images")}</TextH3>
          <ImageThumbnails
            images={allMediaList || []}
            currentSelected={indexMainImageSelected}
            onSelectImage={(e: number) => setIndexMainImageSelected(e)}
          />
        </ImagesContainer>
        <ItemContainer>
          <Header>
            <Ratings>
              <Rating value={parseInt(data.rating)} />
              <SpanReview>({`${data.ratingCount} ${t("reviews")}`})</SpanReview>
            </Ratings>
            <SpaceBetween>
              <PricesDetailProduct
                pricePrefix={
                  mainProductCategory === "machinery" ? `${t("from")}` : ""
                }
                price={data.price}
                salePrice={data.salePrice}
                regularPrice={data.regularPrice}
                currencySymbol={parseCurrencyToSymbol(data.currency)}
              />
              <BuyButtonsContainer>
                {(mainProductCategory === "store" ||
                  mainProductCategory === "machinery" ||
                  mainProductCategory === "wholesale") && (
                  <WhatsAppButton
                    link={window.location.href}
                    phoneNumber={LOGISTIC_PANAMA_PHONE_NUMBER}
                    message={
                      mainProductCategory === "store"
                        ? `${t("interested_in_store_info")}: ${
                            data.name[language]
                          }`
                        : mainProductCategory === "machinery"
                        ? `${t("interested_in_more_machinery_info")}: ${
                            data.name[language]
                          }`
                        : `${t("interested_in_wholesale_info")}:  ${
                            data.name[language]
                          }`
                    }
                  />
                )}
                {mainProductCategory === "store" && isProductAddedInCart ? (
                  <CounterContainer>
                    <CounterAndText>
                      <TextCounter>
                        {detailCurrentProductInCart?.count
                          ? `(${detailCurrentProductInCart?.count})`
                          : ""}{" "}
                        {`${
                          detailCurrentProductInCart?.count &&
                          detailCurrentProductInCart?.count > 1
                            ? `${t("multiple_units")}`
                            : `${t("single_unit")}`
                        } ${t("in_the_cart")}`}
                      </TextCounter>
                      <Counter
                        hideText
                        allowNegative
                        onUpdateQuantity={(quantity: number) => {
                          handleUpdateQuantity(
                            detailCurrentProductInCart?.id,
                            quantity
                          );
                        }}
                        quantity={detailCurrentProductInCart?.count}
                      />
                    </CounterAndText>
                    <Link to={MAIN_ROUTES.Cart}>
                      <ButtonGoToCart onClick={() => {}}>
                        {t("cart")}
                        <ShoppingCartCheckoutIcon />
                      </ButtonGoToCart>
                    </Link>
                  </CounterContainer>
                ) : (
                  data.mainProductCategory === "store" && (
                    <BuyButtons>
                      {isLocalhost && (
                        <Button
                          onClick={() => {
                            if (isAuth) {
                              handleAddToCartAndNavigate();
                            } else {
                              handleBuyNow();
                            }
                          }}
                        >
                          {t("buy")}
                        </Button>
                      )}
                      <StrokedButton onClick={handleAddToCart}>
                        {t("add_to_cart")}
                      </StrokedButton>
                    </BuyButtons>
                  )
                )}
              </BuyButtonsContainer>
            </SpaceBetween>
          </Header>
          <SpaceBetween>
            {data?.sku && <SKU>Sku: {data?.sku}</SKU>}
            <Availability>
              <MdCheckCircle />
              {t("available")}
            </Availability>
          </SpaceBetween>
          {data?.shippingTimeDays ? (
            <ShippingDaysContainer>
              <h6>
                {t("delivery_in")} {`${data.shippingTimeDays} ${t("days")}`}
              </h6>
            </ShippingDaysContainer>
          ) : null}
          {data.shortDescription[language].length > 0 && (
            <General>
              <hr />
              <GeneralTitle>{t("short_description")}</GeneralTitle>
              <GeneralText className="content-product">
                {data.shortDescription
                  ? parse(data.shortDescription[language])
                  : "Producto sin descripción corta"}
              </GeneralText>
            </General>
          )}

          {data.countryOrigin ? (
            <FeatureContainer>
              <FeatureLabel>País de origen:</FeatureLabel>
              <FeatureValue>{data.countryOrigin}</FeatureValue>
            </FeatureContainer>
          ) : null}
        </ItemContainer>
      </ContentProductsContainer>
      <div style={{}}>
        {data.description[language].length > 0 && (
          <General>
            <hr />
            <GeneralTitle>{t("general_details")}</GeneralTitle>
            <GeneralText className="content-product">
              {data.description
                ? parse(data.description[language])
                : "Producto sin descripción"}
            </GeneralText>
          </General>
        )}
      </div>
      <div style={{ margin: "1rem 0 0 0" }}>
        {(mainProductCategory === "store" ||
          mainProductCategory === "machinery" ||
          mainProductCategory === "wholesale") && (
          <WhatsAppButton
            link={window.location.href}
            phoneNumber={LOGISTIC_PANAMA_PHONE_NUMBER}
            message={
              mainProductCategory === "store"
                ? `${t("interested_in_store_info")}: ${data.name[language]}`
                : mainProductCategory === "machinery"
                ? `${t("interested_in_more_machinery_info")}:  ${
                    data.name[language]
                  }`
                : `${t("interested_in_wholesale_info")}:  ${
                    data.name[language]
                  }`
            }
          />
        )}
        <DescriptionContainer>
          {mainProductCategory === "store" &&
          data.description[language].length > 0 &&
          isProductAddedInCart ? (
            <CounterContainer>
              <CounterAndText>
                <TextCounter>
                  {detailCurrentProductInCart?.count
                    ? `(${detailCurrentProductInCart?.count})`
                    : ""}{" "}
                  {`${
                    detailCurrentProductInCart?.count &&
                    detailCurrentProductInCart?.count > 1
                      ? `${t("multiple_units")}`
                      : `${t("single_unit")}`
                  } ${t("in_the_cart")}`}
                </TextCounter>
                <Counter
                  hideText
                  allowNegative
                  onUpdateQuantity={(quantity: number) => {
                    handleUpdateQuantity(
                      detailCurrentProductInCart?.id,
                      quantity
                    );
                  }}
                  quantity={detailCurrentProductInCart?.count}
                />
              </CounterAndText>
              <Link to={MAIN_ROUTES.Cart}>
                <ButtonGoToCart onClick={() => {}}>
                  {t("cart")}
                  <ShoppingCartCheckoutIcon />
                </ButtonGoToCart>
              </Link>
            </CounterContainer>
          ) : (
            data.mainProductCategory === "store" &&
            data?.description[language].length > 0 && (
              <BuyButtons>
                {
                  <Button
                    onClick={() => {
                      if (isAuth) {
                        handleAddToCartAndNavigate();
                      } else {
                        handleBuyNow();
                      }
                    }}
                  >
                    {t("buy")}
                  </Button>
                }
                <StrokedButton onClick={handleAddToCart}>
                  {t("add_to_cart")}
                </StrokedButton>
              </BuyButtons>
            )
          )}
        </DescriptionContainer>
      </div>
      {/* <hr />
            <Reviews>
                    <GeneralTitle>Reseñas</GeneralTitle>
                    <Review>
                        <ReviewHeader>
                            <ReviewProfile>AA</ReviewProfile>
                            <ReviewName>Daniela</ReviewName>
                        </ReviewHeader>
                        <ReviewRating>
                            <MdStarRate/><MdStarRate/><MdStarRate/><MdStarRate/><MdOutlineStarRate/>
                            <SpanReview>Great controller for PC</SpanReview>
                        </ReviewRating>
                        <ReviewLocation>
                            Calificado en Panama el 10 de marzo de 2022
                        </ReviewLocation>
                        <ReviewOption>Color: Carbon Negro</ReviewOption>
                        <ReviewDesc>The controller works great with PC. However, out of the box the Bluetooth feature does not play well with Steam. In order to get it to work, there are a few steps you’ll have to do.</ReviewDesc>
                    </Review>
                </Reviews> 
            <ProductsImmediateDelivery /> */}
      <BackButton
        onClick={() => navigate(-1)}
        text={t("back") as any}
        style={{
          marginTop: "24px",
        }}
      />
      {showModalDelete ? (
        <ModalDeleteConfirm
          isOpen={showModalDelete}
          onClose={() => setShowModalDelete(false)}
          onDelete={handleDeleteFromCart}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default DetailsProduct;
