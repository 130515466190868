import { useEffect, useState } from "react";
import { registerLog } from "libs/analytics";
import { Container } from "./styled";
import MainLayout from "views/layout/MainLayout";
import SliderCarousel from "components/SliderCarousel";
import ServicesForm from "components/Forms/services-form";
import ContentMarketing from "modules/services/components/content-marketing";
import ExtrasServicesContainer from "components/extras-services-container";
import { MarketingTypeCard } from "./constants";
import BannerOnlyText from "components/banner-only-text/banner-only-text";
import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
import { BREAKPOINTS } from "constants/index";
import { useTranslation } from "react-i18next";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

const Marketing = () => {
  const { t } = useTranslation();

  const [bannerImages, setBannerImages] = useState<string[]>([]);

  const {
    collectionData: banners = [],
    loadingGetFirestoreCollection: loadingBanners,
    errorGetFirestoreCollection: errorBanners,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/Marketing/MainBanners",
  });

  const {
    collectionData: marketingData = [],
    loadingGetFirestoreCollection: loadingMarketing,
    errorGetFirestoreCollection: errorMarketing,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/Marketing/Posts",
  });

  const marketingOptions = [
    t("branding"),
    t("outdoor_advertising"),
    t("digital_marketing"),
    t("web_development"),
    t("content_creation"),
    t("social_media_management"),
    t("graphic_design"),
  ];

  useEffect(() => {
    registerLog("Services screen", {
      page_title: "Services",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];
      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners]);

  const processArray = (arr: any[]) => {
    const arrLength = arr?.length || 0;
    const thirdLength = Math.floor(arrLength / 3);

    const firstPart = arr.slice(0, thirdLength);
    const secondPart = arr.slice(thirdLength, 2 * thirdLength);
    const thirdPart = arr.slice(2 * thirdLength);

    return [firstPart, secondPart, thirdPart];
  };

  const marketingCards = marketingData?.filter(
    (item: any) => item.type === MarketingTypeCard.general
  );
  const marketingPlans = marketingData?.filter(
    (item: any) => item.type === MarketingTypeCard.plans
  );
  const marketingExtraServices = marketingData?.filter(
    (item: any) => item.type === MarketingTypeCard.extras
  );

  const listMarketingData = [...(marketingCards || [])];

  const [firstMarketingList, secondMarketingList, thirdMarketingList] =
    processArray(listMarketingData);
  thirdMarketingList.reverse();

  const isLoading = loadingBanners || loadingMarketing;
  const hasError = errorBanners || errorMarketing;

  if (isLoading) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("loading")}</div>
        </Container>
      </MainLayout>
    );
  }

  if (hasError) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("error_loading_services")}</div>
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout type="services">
      <Container>
        <SliderCarousel images={bannerImages} />
        <ServicesForm
          category="Marketing"
          titleFormDescription={t("do_you_have_a_marketing_need")}
          descriptionForm={t(
            "at_whatdoyounees7_we_recognize_that_each_client_has_unique_needs_in_the_world_of_marketing"
          )}
          options={marketingOptions}
        />
        {window.innerWidth > BREAKPOINTS.lg && <BannerOnlyText />}
        <ContentMarketing services={thirdMarketingList || []} />
        <ExtrasServicesContainer
          data={marketingPlans || []}
          title={t("our_plans_for_you")}
          backgroundColor="#E7EDF5"
          hasLink={false}
          hasRedirectToDetail={true}
        />
        <ContentMarketing services={secondMarketingList || []} />
        <ExtrasServicesContainer
          data={marketingExtraServices || []}
          title={t("web_development")}
          backgroundColor="#E7EDF5"
          hasLink={false}
          hasRedirectToDetail={true}
        />
        <ContentMarketing services={firstMarketingList || []} />
        {window.innerWidth < BREAKPOINTS.lg && (
          <BannerFooterMobile
            secondaryText={`${t(
              "increase_your_sales_generate_traffic_and_grow_exponentially"
            )}`}
            text={`${t("advertise_in_the_most_important_media")}`}
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default Marketing;
