import { FC, useState } from "react";
import "./styles.css";

import {
  Card,
  DescriptionContainer,
  Image,
  ImageContainer,
  ShortDescription,
  Title,
  LinkButton,
  WrapperButton,
  Price,
} from "./styled";
import { PriceFromText } from "ui/Text/price-type-text";
import { Multilingual } from "types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setServiceDetail } from "modules/services/store";
import { useSelector } from "react-redux";
import { getLanguage } from "redux/common";
import { useTranslation } from "react-i18next";

interface ExpandCardProps {
  data: {
    id?: string;
    uid?: string;
    image: string;
    images: string[];
    name: Multilingual;
    shortDescription: Multilingual;
    price?: number;
    priceType?: string;
  };
  onClick?: () => void;
  textButton?: string;
  background?: string;
  hasRedirectToDetail?: boolean;
}

const ExpandCard: FC<ExpandCardProps> = ({
  data,
  onClick,
  textButton,
  background,
  hasRedirectToDetail,
}) => {
  const { t } = useTranslation();
  const language = useSelector(getLanguage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonText] = useState(textButton);

  const handleQuoteButtonClick = (sub: any) => {
    dispatch(setServiceDetail(sub));
    if (hasRedirectToDetail) navigate(`detail?id=${data.id}`);
  };

  return (
    <Card height="430" background={background}>
      <ImageContainer>
        <Image
          src={`${data?.images ? data?.images : data?.image}`}
          loading="lazy"
          alt={data?.name?.[language] || ""}
          title={data?.name?.[language] || ""}
        />
      </ImageContainer>
      <DescriptionContainer>
        <Title clamp="2">{data?.name?.[language] || ""}</Title>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: ".5rem",
            marginLeft: data?.priceType === "from" ? ".5rem" : "0",
          }}
        >
          {data?.priceType === "from" ? (
            <PriceFromText> {t("from")} </PriceFromText>
          ) : (
            ""
          )}
          <Price>
            {data?.price ? `$${data?.price}` : t("check_the_price")}
          </Price>
        </div>
        <ShortDescription isExpanded={true} orientation="vertical">
          {data?.shortDescription?.[language]}
        </ShortDescription>
      </DescriptionContainer>
      <WrapperButton>
        <LinkButton
          onClick={() => handleQuoteButtonClick(data)}
          size={"90%"}
          background="#ffffff"
        >
          {buttonText || t("click_here")}
        </LinkButton>
      </WrapperButton>
    </Card>
  );
};

export default ExpandCard;
