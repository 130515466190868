import { useState, useEffect } from "react";
import MainLayout from "views/layout/MainLayout/main-layout";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetProductsByMultiplesCategories } from "hooks/products/useGetProductsByMultipleCategories";
import CategoriesCarousel from "views/containers/SliderCategories";
import ContentProducts from "views/containers/Products/ContentProducts";
import ContentProductsFiltered from "views/containers/Products/ContentProductsFiltered";
import useCategories from "hooks/useGetCategories/useGetCategories";
import { Container, Content } from "./styled";
import { DBCountryReference, FiltersProducts } from "types";
import { DB_ROUTES } from "constants/routes";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
import { buildQueryFilterProducts } from "../main";
import { ProductsMachineryCategories } from "constants/products-constants";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import firebase from "firebase/compat/app";

const { CATEGORIES_MACHINERY } = DB_ROUTES;
const LIST_CATEGORIES: number[] = Object.values(
  ProductsMachineryCategories
).filter((value) => typeof value === "number") as number[];
const COLLECTION_PATH = `${DBCountryReference.PAN}${DB_ROUTES.POSTS_PRODUCTS_MACHINERY}`;

const StoreProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const registerLog = useAnalytics();

  const { selRoute, selRouteEs } = location?.state || "";

  const [category, setCategory] = useState("");
  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const [filters, setFilters] = useState<FiltersProducts>({});

  const { categories } = useCategories(
    `${DBCountryReference.PAN}${CATEGORIES_MACHINERY}`
  );

  const { collectionData: bannersData = [] } = useGetFirestoreCollectionQuery({
    path: `${DBCountryReference.PAN}/Banners/ProductsBanners/Es/Main`,
  });

  const { productsByMultipleCategories } = useGetProductsByMultiplesCategories({
    ids: LIST_CATEGORIES,
    collectionPath: COLLECTION_PATH,
  });

  const queryData = buildQueryFilterProducts(filters);

  const filtersMapped = queryData.query.map((f) => ({
    ...f,
    operator: f.operator as firebase.firestore.WhereFilterOp,
  }));

  const { collectionData: productsByFilters = [] } =
    useGetFirestoreCollectionQuery({
      path: COLLECTION_PATH,
      filters: filtersMapped,
      orderFilter: queryData.orderBy,
      enabled: !!queryData.query && queryData.query.length > 0, // Ejecutar sólo si hay filtros
    });

  const handleSelectCategory = (category: string) => {
    navigate(`/products/category?uid=${category}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productsByMultipleCategories]);

  useEffect(() => {
    if (selRoute !== undefined && selRoute !== "") {
      setCategory(selRouteEs);
    }

    if (bannersData.length > 0) {
      const bannerDoc = bannersData[0];
      const imagesByResolution = () => {
        if (window.innerWidth < 768) {
          return bannerDoc.imagesMobile;
        } else {
          return bannerDoc.imagesDesktop;
        }
      };

      setBannerImages(imagesByResolution() || []);
    }

    registerLog({
      screenName: "Products machinery",
      pageTitle: "Products machinery of whatdoyouneed7.com",
      pagePath: window.location.pathname,
      pageLocation: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersData]);

  const handleSetFilters = (filters: FiltersProducts) => {
    setFilters(filters);
  };

  return (
    <MainLayout type="products">
      <Container>
        <CategoriesCarousel
          category={category}
          onSelectCategory={handleSelectCategory}
          handleType={() => {}}
          background={"linear-gradient(180deg, #B218FF 0%, #8900CE 100%)"}
          type={"Productos"}
          data={categories}
          images={bannerImages}
          hideSlideCategories
        />
        <Content>
          {productsByFilters && productsByFilters.length > 0 ? (
            <ContentProductsFiltered
              data={productsByFilters as any}
              categories={categories}
              setFilters={handleSetFilters}
              filters={filters}
            />
          ) : (
            <ContentProducts
              type="machinery"
              setRoute={setCategory}
              categories={categories}
              setFilters={handleSetFilters}
              data={productsByMultipleCategories}
              titleSection="Maquinaria"
              filters={filters}
            />
          )}
        </Content>
      </Container>
    </MainLayout>
  );
};

export default StoreProducts;
