import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import MainLayout from "views/layout/MainLayout";
import { useSelector } from "react-redux";
import { Container, Content } from "../styled";
import { HeadContent, CarouselCategoriesContainer, Grid } from "./styled";
import Classified from "components/cards/Classifieds/Classifieds";
import CarouselGeneral from "components/carousel-general";
import { BackButton } from "ui/Buttons";
import CardCategoryClassified from "components/Classifieds/card-category-classified";
import SideDrawer from "components/drawers/side-drawer/side-drawer";
import useCategories from "hooks/useGetCategories/useGetCategories";
import { useTranslation } from "react-i18next";
import { H4, H3 } from "ui/Text";
// import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { registerLog } from "libs/analytics";
import { getCountry, getLanguage } from "redux/common";
import { ClassifiedsRoutes } from "constants/classifieds";

const ClassifiedsByCategory = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();
  const country = useSelector(getCountry);
  const language = useSelector(getLanguage);

  const { categories } = useCategories(
    `${country}/${ClassifiedsRoutes.Categories}`,
    [{ field: "active", value: true }],
    { field: "order", direction: "asc" }
  );

  const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1);
  const [route, setRoute] = useState("");
  const { collectionData: classifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        { field: "categoryType", operator: "==", value: lastSegment },
      ],
      enabled: !!lastSegment,
    });

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const categoryName = route
    ? categories.find((category: any) => category?.category === route)
    : null;

  useEffect(() => {
    setRoute(lastSegment);

    registerLog("Classifieds screen by category", {
      page_title: "Classifieds screen by category",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <MainLayout type="classifieds">
      <Container>
        <CarouselCategoriesContainer>
          <Link to={`${ClassifiedsRoutes.Home}`}>
            <BackButton onClick={() => {}} />
          </Link>
          <H3 style={{ margin: "1rem 0" }}>{t("explore_categories")}</H3>
          <CarouselGeneral
            items={categories}
            renderItem={CardCategoryClassified}
            itemsToShow={5}
            itemsMobile={3}
            props={{
              categoryType: route,
            }}
          />
        </CarouselCategoriesContainer>
        <Content>
          <HeadContent>
            <H4 style={{ marginTop: 20 }}>
              {categoryName ? categoryName.name[language] : ""}
            </H4>
            <div style={{ marginRight: "8px" }}>
              <SideDrawer
                buttonLabel={t("filter") as string}
                side="right"
                content={<H4 style={{ marginTop: 20 }}>{t("filters")}</H4>}
                isOpen={sideDrawerOpen}
                setIsOpen={setSideDrawerOpen}
              />
            </div>
          </HeadContent>
          <Grid>
            {classifiedsData &&
              classifiedsData.map((item: any, index: number) => (
                <Classified data={item} key={index} />
              ))}
          </Grid>
        </Content>
      </Container>
    </MainLayout>
  );
};

export default ClassifiedsByCategory;
