import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainLayout from "views/layout/MainLayout";
import { BackButton } from "ui/Buttons";
import { useNavigate, Link } from "react-router-dom";
import { H1 } from "ui/Text";
import {
  Container,
  SubContainer,
  Sidebar,
  Content,
  ChatListContainer,
} from "./styled";
import CardChatClassified from "components/Classifieds/card-chat-classified";
import { MAIN_ROUTES } from "constants/routes";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { getCountry } from "redux/common";
import { userSelector } from "redux/user/selectors";
import { useGetClassifiedListChats } from "hooks/modules/classifieds/use-get-classified-list-chat/use-get-classified-list-chat";

const ChatsClassifieds = () => {
  const navigate = useNavigate();
  const country = useSelector(getCountry);
  const user = useSelector(userSelector)?.userData;
  const CHATS_COLLECTION_PATH = `ChatsClassifieds/${country.toUpperCase()}/Chats`;

  // Reemplazamos fetchUserChats por useGetFirestoreCollectionQuery
  const { collectionData: listChats = [] } = useGetFirestoreCollectionQuery({
    path: CHATS_COLLECTION_PATH,
    filterMode: "OR",
    filters: [
      {
        field: "ownerClassifiedId",
        operator: "==",
        value: user?.id,
      },
      {
        field: "consultantId",
        operator: "==",
        value: user?.id,
      },
    ],
    enabled: !!user?.id, // Ejecutar la query sólo si tenemos user.id
  });

  const { fetchClassifiedChats, collectionData: listChatsWithClassifiedData } =
    useGetClassifiedListChats();
  const [listChatsWithClassifiedInfo, setListChatsWithClassifiedInfo] =
    useState([]);

  useEffect(() => {
    if (listChats && listChats.length) {
      const ids = listChats.map((item) => item.classifiedId);
      fetchClassifiedChats(country, ids);
    }
  }, [listChats, fetchClassifiedChats, country]);

  useEffect(() => {
    if (
      listChatsWithClassifiedData &&
      listChatsWithClassifiedData.length &&
      listChats &&
      listChats.length
    ) {
      const chats = listChatsWithClassifiedData.map((item: any) => {
        const chat = listChats.find((chat) => chat.classifiedId === item.id);
        return {
          ...item,
          ...chat,
        };
      });
      setListChatsWithClassifiedInfo(chats as any);
    }
  }, [listChatsWithClassifiedData, listChats]);

  return (
    <MainLayout type="classifieds">
      <Container>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <H1 style={{ margin: "1rem 0 0 0" }}>Chats de los clasificados</H1>
        <SubContainer>
          <Sidebar />
          <Content>
            <ChatListContainer>
              {listChatsWithClassifiedInfo.map((item: any, index) => {
                return (
                  <Link
                    to={MAIN_ROUTES.ChatSessionClassified}
                    state={{
                      classifiedId: item.classifiedId,
                      consultantId: item.consultantId,
                      ownerClassifiedId: item.ownerClassifiedId,
                    }}
                    key={index}
                  >
                    <CardChatClassified
                      key={index}
                      image={item.images[0]}
                      title={item.title}
                      category={item.category}
                      messages={item.messages}
                    />
                  </Link>
                );
              })}
            </ChatListContainer>
          </Content>
        </SubContainer>
      </Container>
    </MainLayout>
  );
};

export default ChatsClassifieds;
