import { Container } from "./styled";
import { DESTINATIONS_PATH } from "./constants";
import ExtraServicesYachts from "modules/services/pages/yacht/components/Extras/extras-services-container";
import { useTranslation } from "react-i18next";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

interface Props {
  category: string;
}

const RelatedServices = (props: Props) => {
  const { category } = props;
  const { t } = useTranslation();

  const shouldFetch = category === "Yatchs";

  const {
    collectionData: destinationsList = [],
    loadingGetFirestoreCollection: loadingDestinations,
    errorGetFirestoreCollection: errorDestinations,
  } = useGetFirestoreCollectionQuery({
    path: DESTINATIONS_PATH,
    enabled: shouldFetch,
  });

  const { collectionData: extraServicesData = [] } =
    useGetFirestoreCollectionQuery({
      path: "PANAMA/Services/Categories/Yatchs/ExtraServices",
      enabled: shouldFetch,
    });

  return (
    <Container>
      {category === "Yatchs" && (
        <ExtraServicesYachts
          data={extraServicesData}
          title={t("extra_services")}
          backgroundColor="transparent"
          hasLink={false}
          path="services/yatchs"
          type="Yatchs"
        />
      )}
    </Container>
  );
};

export default RelatedServices;
