import { useState, useEffect } from "react";
import MainLayout from "views/layout/MainLayout/main-layout";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetProductsByMultiplesCategories } from "hooks/products/useGetProductsByMultipleCategories";
import CategoriesCarousel from "views/containers/SliderCategories";
import ContentProducts from "views/containers/Products/ContentProducts";
import ContentProductsFiltered from "views/containers/Products/ContentProductsFiltered";
import useCategories from "hooks/useGetCategories/useGetCategories";
import { Container, Content } from "./styled";
import { FiltersProducts } from "types";
import { DB_ROUTES } from "constants/routes";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
import { useTranslation } from "react-i18next";
import { getLanguage, getCountry } from "redux/common";
import { buildQueryFilterProducts } from "../main";
import { ProductStoreCategories } from "constants/products-constants";
import SimpleImageBanner from "ui/simple-image-banner";
import firebase from "firebase/compat/app";

import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

const { CATEGORIES_PRODUCTS_STORE } = DB_ROUTES;
const LIST_CATEGORIES: number[] = Object.values(ProductStoreCategories).filter(
  (value) => typeof value === "number"
) as number[];

const StoreProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector(getLanguage);
  const country = useSelector(getCountry);
  const registerLog = useAnalytics();

  const COLLECTION_PATH = `${country}${DB_ROUTES.POSTS_PRODUCTS_STORE}`;

  const { collectionData: bannersData = [] } = useGetFirestoreCollectionQuery({
    path: `${country}/Banners/ProductsBanners/Es/Main`,
  });

  const { productsByMultipleCategories } = useGetProductsByMultiplesCategories({
    ids: LIST_CATEGORIES,
    collectionPath: COLLECTION_PATH,
  });

  const { categories } = useCategories(
    `${country}${CATEGORIES_PRODUCTS_STORE}`,
    [
      {
        field: "active",
        value: true,
      },
    ]
  );

  const { selRoute, selRouteEs } = location?.state || "";

  const [category, setCategory] = useState("");
  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const [filters, setFilters] = useState<FiltersProducts>({});

  const queryData = buildQueryFilterProducts(filters);

  const filtersMapped = queryData.query.map((f) => ({
    ...f,
    operator: f.operator as firebase.firestore.WhereFilterOp,
  }));

  const { collectionData: productsByFilters = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}${DB_ROUTES.POSTS_PRODUCTS_STORE}`,
      filters: filtersMapped,
      orderFilter: queryData.orderBy,
      enabled: !!queryData.query && queryData.query.length > 0, // Sólo ejecutar si hay filtros
    });

  const handleSelectCategory = (category: string) => {
    navigate(`/products/category?uid=${category}`);
  };

  useEffect(() => {
    if (selRoute !== undefined && selRoute !== "") {
      setCategory(selRouteEs);
    }

    const bannerDoc = bannersData[0];
    if (bannerDoc) {
      const imagesByResolution = () => {
        if (window.innerWidth < 768) {
          return bannerDoc.imagesMobile;
        } else {
          return bannerDoc.imagesDesktop;
        }
      };
      setBannerImages(imagesByResolution() || []);
    }

    registerLog({
      screenName: "Products store",
      pageTitle: "Products store of whatdoyouneed7.com",
      pagePath: window.location.pathname,
      pageLocation: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersData]);

  const handleSetFilters = (filters: FiltersProducts) => {
    setFilters(filters);
  };

  return (
    <MainLayout type="products">
      <Container>
        <CategoriesCarousel
          category={category}
          onSelectCategory={handleSelectCategory}
          handleType={() => {}}
          background={"linear-gradient(180deg, #B218FF 0%, #8900CE 100%)"}
          type={language === "es" ? "productos" : "products"}
          data={categories}
          images={bannerImages}
        />
        <Content>
          {productsByFilters && productsByFilters.length > 0 ? (
            <ContentProductsFiltered
              data={productsByFilters as any}
              categories={categories}
              setFilters={handleSetFilters}
              filters={filters}
            />
          ) : (
            <ContentProducts
              type="store"
              setRoute={setCategory}
              categories={categories}
              setFilters={handleSetFilters}
              data={productsByMultipleCategories}
              titleSection={t("retail_store") || ""}
              filters={filters}
            />
          )}
        </Content>
        <SimpleImageBanner
          hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+ofertas+en+Gaming+y+Softwares+Digitales.&type=phone_number&app_absent=0"
          imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FOfertas%20en%20claves%20de%20windows%2C%20y%20programas%20digitales%2C%20juegos%20y%20membresias.webp?alt=media&token=7fd46d0e-edd3-41a1-98cd-ab723514cd9b"
        />
      </Container>
    </MainLayout>
  );
};

export default StoreProducts;
