import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, ContainerTop, GridAds } from "./styled";
import { useSelector } from "react-redux";
import MainLayout from "views/layout/MainLayout";
import CarouselTop from "views/containers/Carousel";
import { CategoriesSection } from "views/containers/CategoriesSection";
import { BannerInnovation } from "views/containers/Banners/BannerInnovation";
// import { BannerText } from './styled';
// import TopServices from "views/containers/TopServices";
// import VisitProducts from 'views/containers/Products/VisitProducts/visit-products';
import BannerBlog from "components/BannerBlog";
import SimpleImageBanner from "ui/simple-image-banner";
import BlogTextType from "ui/blog-text-type";
import { BannerBrowser } from "views/containers/Banners/BannerBrowser";
// import AdsContainer from "views/containers/Ads";
import useCategories from "hooks/useGetCategories/useGetCategories";
import useAnalytics from "hooks/useAnalytics/useAnalytics";
// import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { getCountry } from "redux/common";
import {
  imageBannerBlogDesktopHandyman,
  imageBannerBlogMobileHandyman,
  imageBannerBlogDesktopRealstate,
  imageBannerBlogMobileRealstate,
  imageBannerBlogDesktopYatch,
  imageBannerBlogMobileYatch,
  imageBannerBlogDesktopPrefabHouse,
  imageBannerBlogMobilePrefabHouse,
  imageBannerBlogMobileGanaDinero,
  imageBannerBlogDesktopGanaDinero,
  imageBannerBlogMobileIptv,
  imageBannerBlogDesktopIptv,
  imageBannerBlogDesktopStarlink,
  imageBannerBlogMobileStarlink,
  imageBannerBlogMobileBuyFromChina,
  imageBannerBlogDesktopBuyFromChina,
  imageBannerBlogMobileBannerSolarPanel,
  imageBannerBlogDesktopBannerSolarPanel,
  imageBannerBlogDesktopTrapeador,
  imageBannerBlogMobileTrapeador,
  imageBannerBlogMobileAuditoriaFinanciera,
  imageBannerBlogDesktopAuditoriaFinanciera,
  imageBannerBlogMobileIslaContadora,
  imageBannerBlogDesktopIslaContadora,
  imageBannerBlogMobileMarketing,
  imageBannerBlogDesktopMarketing,
  imageBannerBlogDesktopProductosPromocionales,
  imageBannerBlogMobileProductosPromocionales,
} from "./constants";
import { ImageResponsive } from "types";
import { getLanguage } from "redux/common/selector";
import { capitalize } from "utils/common";

const Home: FC = () => {
  const registerLog = useAnalytics();
  const { t } = useTranslation();
  const currentCountry = useSelector(getCountry);
  const currentLanguage = useSelector(getLanguage);

  const { categories } = useCategories(`${currentCountry}/Categories/General`, [
    { field: "active", value: true },
  ]);

  const { collectionData: banners = [] } = useGetFirestoreCollectionQuery({
    path: `${currentCountry}/Banners/DotsBanners/${capitalize(
      currentLanguage
    )}/Items`,
  });

  const [dotsBanners, setDotsBanners] = useState<ImageResponsive[] | []>([]);

  useEffect(() => {
    registerLog({
      screenName: "Home",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
      pageTitle: "Home",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    if (numRenders < 10) {
      setNumRenders(numRenders + 1);
      if (banners && banners.length > 0) {
        const listBanners: Image[] = [];
        banners.forEach((doc: any) => {
          listBanners.push({
            imageDesktop: doc.imageDesktop,
            imageMobile: doc.imageMobile,
          });
        });
        setDotsBanners(listBanners);
      }
    }


  }, [banners]); */

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners = banners.map((doc: any) => ({
        imageDesktop: doc.imageDesktop,
        imageMobile: doc.imageMobile,
      }));
      setDotsBanners(listBanners);
    }
  }, [banners]);

  return (
    <>
      <MainLayout>
        <Container id="home-container">
          <ContainerTop>
            {window.innerWidth > 992 && <CarouselTop images={dotsBanners} />}
            <CategoriesSection categories={categories} />
          </ContainerTop>
          <BannerInnovation />
          <GridAds>
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopGanaDinero}
              imageMobile={imageBannerBlogMobileGanaDinero}
              bannerText={t("register_banner")}
              message={t("register_banner")}
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopBuyFromChina}
              imageMobile={imageBannerBlogMobileBuyFromChina}
              bannerText={t("buy_from_china")}
              message={t("buy_from_china_messages")}
            />
          </GridAds>
          <GridAds>
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopYatch}
              imageMobile={imageBannerBlogMobileYatch}
              bannerText={t("yatch_banner_text")}
              message={t("yatch_banner_text_whatsapp")}
            />
            <SimpleImageBanner
              hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+Zapatillas+New+Balance.&type=phone_number&app_absent=0"
              hideDesktop
              imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FNewBalanceAds.webp?alt=media&token=36d0310d-4a92-424a-9b11-f9fc22b0e3aa"
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopIptv}
              imageMobile={imageBannerBlogMobileIptv}
              bannerText={t("iptv_banner_text")}
              message={t("iptv_banner_text_messages")}
              size="big"
            />
          </GridAds>
          <SimpleImageBanner
            hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+los+descuentos+en+la+saga+de+videojuegos+Fallout.&type=phone_number&app_absent=0"
            hideMobile
            imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FFallout%20consigue%20descuentos%20en%20videojuegos.webp?alt=media&token=40d7fe10-31fe-4fe9-9d31-f6efc84de040"
          />
          <GridAds>
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopBannerSolarPanel}
              imageMobile={imageBannerBlogMobileBannerSolarPanel}
              bannerText={t("solar_panel_banner")}
              message={t("solar_panel_banner_messages")}
            />
            <div>
              <BlogTextType
                text={t("divisas_text_banner")}
                image="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FIntercambios%20de%20divisas%2C%20transacciones%20internacionales%2C%20pagos%20seguros.webp?alt=media&token=0c499bcc-074e-446a-9cad-ca4c9852e817"
              />
              <BannerBlog
                imageDesktop={imageBannerBlogDesktopHandyman}
                imageMobile={imageBannerBlogMobileHandyman}
                bannerText={t("handyman_banner")}
                message={t("handyman_banner_messages")}
              />
            </div>
          </GridAds>
          <GridAds>
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopRealstate}
              imageMobile={imageBannerBlogMobileRealstate}
              bannerText={t("realstate_banner_text")}
              message={t("realstate_banner_text_messages")}
            />
            <SimpleImageBanner
              hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+ofertas+en+Gaming+y+Softwares+Digitales.&type=phone_number&app_absent=0"
              hideDesktop
              imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FOfertas%20en%20keys%2Cjuegos%20y%20software%20digital.webp?alt=media&token=ff97708d-86bb-43c9-9c69-1a804f45e764"
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopPrefabHouse}
              imageMobile={imageBannerBlogMobilePrefabHouse}
              bannerText={t("prefab_house")}
              message={t("prefab_house_messages")}
            />
          </GridAds>
          <SimpleImageBanner
            hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+del+Samsung+Galaxy+S24+Ultra.&type=phone_number&app_absent=0"
            hideDesktop
            imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FSamsung%20s24%20ultra.webp?alt=media&token=a09b0c05-9817-4088-b894-a8cde17fcd92"
          />
          <GridAds>
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopStarlink}
              imageMobile={imageBannerBlogMobileStarlink}
              bannerText={t("starlink_banner")}
              size="big"
              message={t("starlink_banner_messages")}
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopTrapeador}
              imageMobile={imageBannerBlogMobileTrapeador}
              bannerText={t("trapeador_absorvente")}
              message={t("trapeador_messages")}
            />
          </GridAds>
          <SimpleImageBanner
            hrefLink="https://wa.link/u5d7te"
            hideMobile
            imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2Fvende%20productos%20en%20nuestra%20plataforma%20y%20gana%20dinero.webp?alt=media&token=d11cb1b1-b0e2-4007-8936-2b4d0409d04d"
          />
          <GridAds>
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopAuditoriaFinanciera}
              imageMobile={imageBannerBlogMobileAuditoriaFinanciera}
              bannerText={t("auditoria_empresas")}
              message={t("auditoria_messages")}
            />
            <SimpleImageBanner
              hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+la+Ashwagandha.&type=phone_number&app_absent=0"
              hideDesktop
              imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FAshwagandha%20para%20reducir%20el%20estres%20y%20la%20ansiedad%20en%20panama%CC%81.webp?alt=media&token=2071a7ee-2fbb-4c4a-ac15-5831cb6183b2"
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopIslaContadora}
              imageMobile={imageBannerBlogMobileIslaContadora}
              bannerText={t("archipielago_perlas")}
              message={t("archipielago_messages")}
            />
            <SimpleImageBanner
              hrefLink="https://api.whatsapp.com/send/?phone=%2B50764055638&text=Hola%21+Deseo+saber+mas+informaci%C3%B3n+acerca+de+las+ofertas+en+Gaming+y+Softwares+Digitales.&type=phone_number&app_absent=0"
              hideDesktop
              imageUrl="https://firebasestorage.googleapis.com/v0/b/wdyn7-app.appspot.com/o/PANAMA%2FBanners%2FSpanish%2FGana%20dinero%20en%20internet%20desde%20cualquier%20parte%20del%20mundo.webp?alt=media&token=849bd545-9343-48e8-852a-0b3b8466d537"
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopMarketing}
              imageMobile={imageBannerBlogMobileMarketing}
              bannerText={t("marketing_blog")}
              message={t("marketing_blog_messages")}
            />
            <BannerBlog
              imageDesktop={imageBannerBlogDesktopProductosPromocionales}
              imageMobile={imageBannerBlogMobileProductosPromocionales}
              bannerText={t("ProductosPromocionales_blog")}
              message={t("marketing_blog_messages")}
            />
          </GridAds>
          <BannerBrowser text={t("banner_sale_your_products") || ""} />
        </Container>
      </MainLayout>
    </>
  );
};

export default Home;
