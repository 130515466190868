import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  CarouselServicesContainer,
  ContentServicesContainer,
  ListServicesContainer,
  TitleContainer,
  SubtitleContainer,
} from "./styled";
import { H2, H3 } from "ui/Text";
import BasicCardService from "components/cards/services-card";
import SlickCarousel from "components/SlickCarousel";
import TopServices from "views/containers/TopServices";
import { CATEGORIES_SERVICES_IDS } from "constants/constants";
import { Service } from "types";
import { useTranslation } from "react-i18next";
/* import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore"; */
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { getCountry } from "redux/common";
interface Props {
  data: Service[];
}

const ContentServices = (props: Props) => {
  const { data } = props;
  const country = useSelector(getCountry);
  const { t } = useTranslation();
  const [yachtServices, setYachtServices] = useState<any[]>([]);
  //const { collectionData: yachts, fetchCollection: getServicesByCategory } = useGetFirestoreCollection();

  const { collectionData: yachts } = useGetFirestoreCollectionQuery({
    path: `${country}/Services/Posts`,
    filters: [
      {
        field: "idCategory",
        operator: "==",
        value: CATEGORIES_SERVICES_IDS.yachts,
      },
    ],
  });
  /*   useEffect(() => {
    getServicesByCategory({
      path: `${country}/Services/Posts`,
      filters: [
        {
          field: "idCategory",
          operator: "==",
          value: CATEGORIES_SERVICES_IDS.yachts,
        },
      ],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServicesByCategory]); */

  useEffect(() => {
    if (yachts && yachts.length > 0) {
      const yachtsCards: string[] = [];
      yachts.forEach((doc: any) => {
        yachtsCards.push(doc);
      });
      setYachtServices(yachtsCards);
    }
  }, [yachts]);

  return (
    <Container>
      <TitleContainer>
        <H2>{t("services")}</H2>
      </TitleContainer>
      <TopServices style={{ background: "transparent" }} />
      {data.length > 0 || yachtServices.length > 0 ? (
        <ContentServicesContainer>
          <ListServicesContainer>
            <SubtitleContainer>
              <H3>{t("most_demanded")}</H3>
            </SubtitleContainer>
            <CarouselServicesContainer>
              <SlickCarousel
                items={data}
                renderItem={BasicCardService}
                itemsToShow={5}
                itemsToTablet={3}
                itemsMobile={2}
              />
            </CarouselServicesContainer>
          </ListServicesContainer>
        </ContentServicesContainer>
      ) : (
        <div>
          <p>{t("no_services_available")}</p>
        </div>
      )}
    </Container>
  );
};

export default ContentServices;
