import { useEffect, useState } from "react";
import { Container } from "./styled";
import { registerLog } from "libs/analytics";
import MainLayout from "views/layout/MainLayout";
import SliderCarousel from "components/SliderCarousel";
import Directory from "modules/services/components/directory/directory";
import BannerFooterMobile from "components/banner-footer-mobile/banner-footer-mobile";
import { getProducts } from "services/products/get-products-services";
import { BREAKPOINTS, CATEGORIES_SERVICES_IDS } from "constants/index";
import ServicesForm from "components/Forms/services-form";
import {
  MESSAGE_FOOTER_BANNER,
  /*   OPTIONS_FORM_TECHNICAL_SERVICE, */
} from "./constants";
import { TECHNICAL_PATH } from "../yacht/constants";
import { useTranslation } from "react-i18next";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

const TechnicalService = () => {
  const { t } = useTranslation();

  const {
    collectionData: banners = [],
    loadingGetFirestoreCollection: loadingBanners,
    errorGetFirestoreCollection: errorBanners,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/TechnicalServices/Banners",
  });

  const {
    collectionData: technicalServiceData = [],
    loadingGetFirestoreCollection: loadingTechnicalServices,
    errorGetFirestoreCollection: errorTechnicalServices,
  } = useGetFirestoreCollectionQuery({
    path: TECHNICAL_PATH,
  });

  const {
    collectionData: technicalServiceCategoriesData = [],
    loadingGetFirestoreCollection: loadingTechnicalCategories,
    errorGetFirestoreCollection: errorTechnicalCategories,
  } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Services/Categories/TechnicalServices/Categories",
  });

  const servicesOptionsMock = [
    t("appliance_repair"),
    t("appliance_maintenance"),
    t("appliance_installation"),
    t("computer_repair"),
    t("software_installation"),
    t("cctv_solutions"),
    t("others"),
  ];

  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const [contentDirectory, setContentDirectory] = useState<any[]>([]);
  const [, setRelatedProducts] = useState<any[]>([]);

  useEffect(() => {
    registerLog("Services technical support", {
      page_title: "Services technical support",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    getProducts({}, CATEGORIES_SERVICES_IDS.smartHome).then((res) => {
      setRelatedProducts(res);
    });

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const listBanners: string[] = [];
      banners.forEach((doc: any) => {
        if (window.innerWidth < 768) {
          listBanners.push(doc.imagesMobile.es);
        } else {
          listBanners.push(doc.imagesDesktop.es);
        }
      });
      setBannerImages(listBanners);
    }
  }, [banners]);

  useEffect(() => {
    if (
      technicalServiceCategoriesData &&
      technicalServiceCategoriesData.length > 0
    ) {
      const technicalServiceCategories = technicalServiceCategoriesData.map(
        (category: any) => {
          const services = technicalServiceData.filter(
            (service: any) => service.categoryName === category.categoryName
          );
          return {
            title: category.categoryName,
            services,
          };
        }
      );

      setContentDirectory(technicalServiceCategories || []);
    }
  }, [technicalServiceData, technicalServiceCategoriesData]);

  const isLoading =
    loadingBanners || loadingTechnicalServices || loadingTechnicalCategories;
  const hasError =
    errorBanners || errorTechnicalServices || errorTechnicalCategories;

  if (isLoading) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("loading")}</div>
        </Container>
      </MainLayout>
    );
  }

  if (hasError) {
    return (
      <MainLayout type="services">
        <Container>
          <div>{t("error_loading_services")}</div>
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout type="services">
      <Container>
        <SliderCarousel images={bannerImages} />
        <ServicesForm
          category="TechnicalService"
          titleFormDescription={t("do_you_have_a_need_for_technical_service")}
          descriptionForm={t(
            "at_whatdoyouneed7_we_understand_the_need_to_have_your_devices_and_appliances_in_optimal_condition"
          )}
          options={servicesOptionsMock}
        />
        {contentDirectory.length > 0 &&
          contentDirectory.map((directory) => (
            <Directory
              key={directory.title}
              title={directory.title}
              services={directory.services || []}
            />
          ))}
        {/* <RelatedProducts title="Productos relacionados" data={relatedProducts || []} /> */}
        {window.innerWidth < BREAKPOINTS.lg && (
          <BannerFooterMobile
            data={MESSAGE_FOOTER_BANNER}
            secondaryText="Conéctate con clientes que necesitan soluciones técnicas, desde reparaciones de electrodomésticos hasta asistencia informática"
            text="¿Ofreces servicios técnicos? muestra tus servicios en nuestra plataforma"
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default TechnicalService;
