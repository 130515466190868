import { onSnapshot, collection as collectionFull } from "firebase/firestore";
import { firestoreFull } from "config/firebase";
import { UserOrder } from "types";

/**
 * @description Function to retrieve all orders from a user
 * @returns {Promise<UserOrder>}
 */
export const getUserOrders = (userUid: string, setOrders: (orders: UserOrder[]) => void) => {
    const userOrderRef = collectionFull(firestoreFull, `Users/${userUid}/Orders`);

    const unsubscribe = onSnapshot(
        userOrderRef,
        (snapshot) => {
        const orders: any = snapshot.docs.map(doc => ({ ...doc.data()}));
        setOrders(orders);
        },
        (error) => {
            console.error('Error retrieving user orders', error);
        }
    );

    return unsubscribe;
}



