/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  SearchBarContainer,
  Input,
  Container,
  ResultsContainer,
  Result,
  ResultItem,
  TextItem,
  ClearButton,
} from "./styled";
import SearchIcon from "assets/icons/SearchIcon";
import Spinner from "ui/Spinner/spinner";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getLanguage } from "redux/common";
import { iconUrls } from "assets/icons/icon-url-list";
import { debounce } from "lodash";

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  results: any;
  isSearching: boolean;
  clearResults: () => void;
}

const disableBodyScroll = () => {
  document.body.style.overflow = "hidden";
};

const enableBodyScroll = () => {
  document.body.style.overflow = "";
};

const SearchBar = (props: IProps) => {
  const { onChange, results, clearResults, isSearching } = props;
  const { t } = useTranslation();
  const language = useSelector(getLanguage).toLowerCase();

  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    if (results.length > 0) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [results]);

  const debouncedOnChange = useCallback(debounce(onChange, 300), []);

  useEffect(() => {
    if (debouncedOnChange && localValue) {
      debouncedOnChange({
        target: { value: localValue },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [localValue, debouncedOnChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const renderText = (obj: any) => {
    return Object.keys(obj).map((key, index) => {
      if (
        typeof obj[key] === "object" &&
        index === 0 &&
        obj[key] !== null &&
        obj[key].hasOwnProperty(language || "es")
      ) {
        return <TextItem key={key}>{obj[key]?.[language || "es"]}</TextItem>;
      } else if (typeof obj[key] !== "object" && index === 0) {
        return (
          <TextItem key={key}>
            {obj.name?.[language || "es"] || obj[key]}
          </TextItem>
        );
      }
      return null;
    });
  };

  return (
    <Container>
      <SearchBarContainer>
        <Input
          value={localValue}
          onChange={handleChange}
          type="text"
          placeholder={t("search") || ""}
        />
        {isSearching ? (
          <Spinner />
        ) : localValue.length === 0 && !results.length ? (
          <SearchIcon />
        ) : (
          <ClearButton
            onClick={() => {
              setLocalValue("");
              clearResults();
            }}
          >
            <HighlightOffIcon style={{ color: "#000" }} />
          </ClearButton>
        )}
      </SearchBarContainer>
      <ResultsContainer>
        {results.map((result: any) => {
          const handleRoute = () => {
            const listOfindex = [
              "search-panama-classifieds",
              "search-panama-products",
              "search-panama-services",
            ];
            const url = () => {
              switch (result._index) {
                case listOfindex[0]:
                  return `/classified/detail?id=${result?._source?.id}`;
                case listOfindex[1]:
                  return `/product/detail?uid=${result?._source?.id}&mainCategory=${result?._source?.mainProductCategory}`;
                case listOfindex[2]:
                  return `/service/detail?id=${result?._source?.id}`;
                default:
                  return "";
              }
            };
            return url();
          };

          const handleParams = () => {
            const listOfindex = [
              "search-panama-classifieds",
              "search-panama-products",
              "search-panama-services",
            ];
            const handle = () => {
              switch (result._index) {
                case listOfindex[0]:
                  return { ruta: "PANAMA/Classifieds/Posts" };
                case listOfindex[1]:
                  return { ruta: "", product: {} };
                case listOfindex[2]:
                  return { ruta: "PANAMA/Services/PostsFeatureds" };
                default:
                  return {};
              }
            };
            return handle();
          };

          const params = handleParams();

          return (
            <Link
              key={result._id}
              to={handleRoute()}
              state={params}
              onClick={() => {
                if (result._index) {
                  setLocalValue("");
                  clearResults();
                }
              }}
            >
              <Result>
                <img
                  src={result._source?.images?.[0] || iconUrls.noImage256x256}
                  loading="lazy"
                  alt={result._source?.name?.es || ""}
                  style={{ height: "3rem" }}
                />
                <ResultItem>{renderText(result._source)}</ResultItem>
              </Result>
            </Link>
          );
        })}
      </ResultsContainer>
    </Container>
  );
};

export default SearchBar;

// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import { SearchBarContainer, Input, Container, ResultsContainer, Result, ResultItem, TextItem, ClearButton } from './styled';
// import SearchIcon from 'assets/icons/SearchIcon';
// import Spinner from 'ui/Spinner/spinner';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { getLanguage } from 'redux/common';
// import { iconUrls } from 'assets/icons/icon-url-list';

// interface IProps {
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   results: any;
//   isSearching: boolean;
//   clearResults: () => void;
// }

// const disableBodyScroll = () => {
//   document.body.style.overflow = 'hidden';
// };

// const enableBodyScroll = () => {
//   document.body.style.overflow = '';
// };

// const SearchBar = (props: IProps) => {
//   const { onChange, results, clearResults } = props;
//   const { t } = useTranslation();
//   const language = useSelector(getLanguage).toLowerCase();

//   const [localValue, setLocalValue] = useState('');

//   useEffect(() => {
//     if (results.length > 0) {
//       disableBodyScroll();
//     } else {
//       enableBodyScroll();
//     }
//   }, [results]);

//   useEffect(() => {
//     if (onChange && localValue) {
//       onChange({ target: { value: localValue } } as React.ChangeEvent<HTMLInputElement>);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [localValue]);

//   const renderText = (obj: any) => {
//     return Object.keys(obj).map((key, index) => {
//       if (typeof obj[key] === 'object' && index === 0 && obj[key] !== null && obj[key].hasOwnProperty( language || 'es')) {
//         return <TextItem key={key}>{obj[key]?.[language || 'es']}</TextItem>;
//       } else if (typeof obj[key] !== 'object' && index === 0) {
//         return <TextItem key={key}>{ obj.name?.[language || 'es'] || obj[key] }</TextItem>;
//       }
//       return null;
//     });
//   };

//   return (
//     <Container>
//       <SearchBarContainer>
//         <Input
//           value={localValue}
//           onChange={(e: { preventDefault: () => void; target: { value: any; }; }) => {
//             e.preventDefault();
//             setLocalValue(e.target.value);
//           }}
//           type="text"
//           placeholder={t('search') || ''}
//         />
//         {props.isSearching ? <Spinner /> : localValue.length === 0 && !results.length ? <SearchIcon /> : (
//           <ClearButton onClick={() => { setLocalValue(''); clearResults(); }}>
//             <HighlightOffIcon style={{ color: '#000' }} />
//           </ClearButton>
//         )}
//       </SearchBarContainer>
//       <ResultsContainer>
//         {results.map((result: any) => {
//           const handleRoute = () => {
//             const listOfindex = ['search-panama-classifieds', 'search-panama-products', 'search-panama-services']
//             const url = () => {
//               switch (result._index) {
//                 case listOfindex[0]:
//                   return `/classified/detail?id=${result?._source?.id}`
//                 case listOfindex[1]:
//                   return `/product/detail?uid=${result?._source?.id}&mainCategory=${result?._source?.mainProductCategory}`
//                 case listOfindex[2]:
//                   return `/service/detail?id=${result?._source?.id}`
//                 default:
//                   return ''
//               }
//             }

//             return url()
//           }

//           const handleParams = () => {
//             const listOfindex = ['search-panama-classifieds', 'search-panama-products', 'search-panama-services']

//             const handle = () => {
//               switch (result._index) {
//                 case listOfindex[0]:
//                   return { ruta: "PANAMA/Classifieds/Posts" }
//                 case listOfindex[1]:
//                   return { ruta: "", product: {} }
//                 case listOfindex[2]:
//                   return { ruta: 'PANAMA/Services/PostsFeatureds' }
//                 default:
//                   return {}
//               }
//             }

//             return handle()
//           }

//           const params = handleParams()

//           return (
//             <Link key={result._id} to={handleRoute()} state={params}>
//               <Result >
//                 <img src={result._source?.images?.[0] || iconUrls.noImage256x256} loading='lazy' alt={result._source?.name?.es || ''} style={{ height: '3rem' }} />
//                 <ResultItem >{renderText(result._source)}</ResultItem>
//               </Result>
//             </Link>
//           );
//         })}
//       </ResultsContainer>
//     </Container>
//   );
// };

// export default SearchBar;
