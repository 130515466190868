import {
  collection,
  getDocs,
  query,
  where,
  /*  DocumentData, */
  orderBy,
} from "firebase/firestore/lite";
import { firestore } from "config/firebase";
import firebase from "firebase/compat/app";
import { useQuery } from "react-query";

interface FirestoreDoc extends Record<string, any> {
  uid?: string;
}

interface Filter {
  field: string;
  operator: firebase.firestore.WhereFilterOp;
  value:
    | string
    | number
    | boolean
    | null
    | firebase.firestore.FieldPath
    | number[];
}

interface OrderByFilter {
  field?: string;
  direction?: "asc" | "desc";
}

interface FetchOptions {
  path: string;
  filters?: Filter[];
  orderFilter?: OrderByFilter;
  filterMode?: "AND" | "OR";
  enabled?: boolean; // Opción para habilitar/deshabilitar la ejecución de la query
}

export async function getFirestoreCollection(
  options: FetchOptions
): Promise<FirestoreDoc[]> {
  const { path, filters = [], orderFilter, filterMode = "AND" } = options;
  const db = firestore;

  if (filterMode === "OR") {
    const queries = filters.map((filter) => {
      const { field, operator, value } = filter;
      const q = query(collection(db, path), where(field, operator, value));
      return getDocs(q);
    });

    const results = await Promise.all(queries);
    let data = results.flatMap((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
    );

    const uniqueData = Array.from(
      new Map(data.map((item) => [item.uid, item])).values()
    );
    data = uniqueData;

    if (orderFilter?.field && orderFilter.direction) {
      data = data.sort((a, b) => {
        const field = orderFilter.field as string;
        if (orderFilter.direction === "asc") {
          return a[field] > b[field] ? 1 : -1;
        } else {
          return a[field] < b[field] ? 1 : -1;
        }
      });
    }

    return data;
  } else {
    let firestoreQuery: any = collection(db, path);

    filters.forEach((filter) => {
      const { field, operator, value } = filter;
      firestoreQuery = query(firestoreQuery, where(field, operator, value));
    });

    if (orderFilter?.field && orderFilter.direction) {
      const { field, direction } = orderFilter;
      firestoreQuery = query(firestoreQuery, orderBy(field, direction));
    }

    const querySnapshot = await getDocs(firestoreQuery);
    return querySnapshot.docs.map((doc) => {
      const data = doc.data() as Record<string, any>;
      return { ...data, id: doc.id };
    });
  }
}

export const useGetFirestoreCollectionQuery = (options: FetchOptions) => {
  const {
    path,
    filters = [],
    orderFilter,
    filterMode = "AND",
    enabled = true,
  } = options;

  const queryKey = [
    "firestoreCollection",
    path,
    filterMode,
    JSON.stringify(filters),
    JSON.stringify(orderFilter),
  ];

  const { data, error, isLoading, refetch } = useQuery(
    queryKey,
    () => getFirestoreCollection({ path, filters, orderFilter, filterMode }),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: 1,
      enabled, // Utiliza el valor habilitado/deshabilitado
      onError: (error) => {
        console.error("Error fetching Firestore collection:", error);
      },
    }
  );

  return {
    collectionData: data ?? [],
    loadingGetFirestoreCollection: isLoading,
    errorGetFirestoreCollection: error,
    refetchCollectionData: refetch,
  };
};
