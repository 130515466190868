import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MainLayout from "views/layout/MainLayout";
import { Container, Content } from "./styled";
import EditClassifiedFormSelector from "views/containers/Classifieds/edit-classified/container-edit-classified";
// import { useGetFirestoreCollection } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import { registerLog } from "libs/analytics";
import { getLanguage } from "redux/common";
import useEditClassified from "hooks/classifieds/useUpdateClassified/use-update-classified";

const EditClassified = () => {
  const location = useLocation();
  const language = useSelector(getLanguage);
  const category = location?.state?.category;
  const categoryName = location?.state?.categoryName;
  // const id = location?.state?.id;
  const classifiedData = location?.state?.classifiedData;

  const { collectionData: categoryData = [] } = useGetFirestoreCollectionQuery({
    path: "PANAMA/Classifieds/Categories",
    filters: [
      {
        field: "category",
        operator: "==",
        value: category,
      },
    ],
    enabled: !!category,
  });

  const [formProperties, setFormProperties] = useState<any>({});

  const { dataClassifiedEdited, updateError, editClassified } =
    useEditClassified(); // isUpdating,

  async function handlerUploadImagesToFirestorage(
    files: File[],
    path: string
  ): Promise<string[]> {
    const {
      downloadURLs,
      isLoading: loadingUploadImages,
      error,
    }: any = await handlerUploadImagesToFirestorage(files, path);

    if (loadingUploadImages) {
      setIsLoading(true);
    }

    if (!loadingUploadImages) {
      setIsLoading(false);
    }

    if (error) {
      console.log("Error al subir imágenes:", error);
      alert("Error al subir imágenes");
    }

    return downloadURLs;
  }

  useEffect(() => {
    // analytics
    registerLog("Edit Classified screen", {
      page_title: "Edit classified",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      setFormProperties(categoryData[0].formProperties);
    }
  }, [categoryData]);

  useEffect(() => {
    if (dataClassifiedEdited) {
      console.log("Clasificado actualizado exitosamente", dataClassifiedEdited);
      // showSuccess("Clasificado actualizado exitosamente");
      // setSuccessUpdateClassified(true);
    }

    if (updateError) {
      console.log("error de actualizar clasificado", updateError);
      // showError("Error al actualizar clasificado");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataClassifiedEdited, updateError]);

  const handleUpdateClassified = async (updatedData) => {
    const imagesBlob = updatedData.images.filter(
      (image: any) => image instanceof File
    );
    const imagesUrls = updatedData.images.filter(
      (image: any) => typeof image === "string"
    );
    const pathFirestorage = updatedData.imagesReference;
    const imagesUrlsRecentUploaded = await handlerUploadImagesToFirestorage(
      imagesBlob,
      pathFirestorage
    );

    const updatedDataWithImagesUrls = {
      ...updatedData,
      images: [...imagesUrls, ...imagesUrlsRecentUploaded],
    };

    editClassified(updatedDataWithImagesUrls);
  };

  if (!category || !formProperties || !categoryData || !classifiedData) {
    return <div>Cargando...</div>;
  }

  return (
    <MainLayout type="classifieds">
      <Container>
        <Content>
          <EditClassifiedFormSelector
            initialValues={classifiedData}
            category={category}
            isEditMode={true}
            language={language}
            categoryName={categoryName}
            formProperties={formProperties}
            classifiedData={classifiedData} // Pasa los datos del clasificado
            onUpdate={handleUpdateClassified} // Añadir la función para manejar la actualización
          />
        </Content>
      </Container>
    </MainLayout>
  );
};

export default EditClassified;
function setIsLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
