import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';
import { Timestamp } from 'firebase/firestore';

dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale('es');

// Definir la zona horaria local (si no está especificada, Day.js usará la de la máquina)
const localTimezone = dayjs.tz.guess(); 

const customCalendarFormat = {
  sameDay: 'h:mm A',
  nextDay: '[Mañana]',
  nextWeek: 'dddd',
  lastDay: '[Ayer]',
  lastWeek: '[El] dddd',
  sameElse: 'DD/MM/YYYY'
};

// Función para formatear la fecha al estilo WhatsApp
export const formatDate = (dateString: any) => {
  return dayjs(dateString).tz(localTimezone).calendar(null, customCalendarFormat);
};

export const timeStampToDateString = (timestamp: Timestamp): string => {
  if (!timestamp || !(timestamp instanceof Timestamp)) {
    return formatDate(timestamp);
  }

  return dayjs(timestamp.toDate())
    .tz(localTimezone)
    .calendar(null, customCalendarFormat);
};

