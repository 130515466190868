import { useEffect, useState } from "react";
import MainLayout from "views/layout/MainLayout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Container, Content } from "./styled";
import BannerAndCategories from "views/containers/SliderCategories";
import ContentClassifieds from "views/containers/Classifieds/ContentClassifieds";
import FloatButton from "components/float-button";
import useCategories from "hooks/useGetCategories/useGetCategories";
import useIsAuth from "hooks/user/useIsAuth/useIsAuth";
import { useNotification } from "libs/context/AlertContext";
import { registerLog } from "libs/analytics";
import { getCountry, getLanguage } from "redux/common";
import { ClassifiedsRoutes, ClassifiedCategories } from "constants/classifieds";
import { capitalize } from "lodash";
import { MAIN_ROUTES } from "constants/routes";
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";

const Classifieds = () => {
  const isAuth = useIsAuth();
  const country = useSelector(getCountry);
  const language = useSelector(getLanguage);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showWarning } = useNotification();

  const { categories } = useCategories(
    `${country}/Classifieds/Categories`,
    [{ field: "active", value: true }],
    { field: "order", direction: "asc" }
  );

  const [route, setRoute] = useState("");
  const [, setTypeCategory] = useState("");
  const [bannerImages, setBannerImages] = useState<string[]>([]);

  const { collectionData: banners = [] } = useGetFirestoreCollectionQuery({
    path: `${country}/Classifieds/MainBanners/${capitalize(language)}/Banners`,
  });

  const { collectionData: suggestedClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [{ field: "status", operator: "==", value: "approved" }],
    });

  const { collectionData: RealEstateClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.RealState,
        },
      ],
    });

  const { collectionData: VehicleClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Vehicles,
        },
      ],
    });

  const { collectionData: EmploymentClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Employments,
        },
      ],
    });

  const { collectionData: TechClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Tech,
        },
      ],
    });

  const { collectionData: ConstructionClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Construction,
        },
      ],
    });

  const { collectionData: HomeClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Home,
        },
      ],
    });

  const { collectionData: PetsClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Pets,
        },
      ],
    });

  const { collectionData: MusicClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Music,
        },
      ],
    });

  const { collectionData: SportsClassifiedsData = [] } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [
        { field: "status", operator: "==", value: "approved" },
        {
          field: "categoryType",
          operator: "==",
          value: ClassifiedCategories.Sports,
        },
      ],
    });

  useEffect(() => {
    registerLog("Classifieds screen", {
      page_title: "Classifieds Home Screen",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      const bannerDoc = banners[0];
      const imagesByResolution = () => {
        if (window.innerWidth < 768) {
          return bannerDoc.imageMobile;
        } else {
          return bannerDoc.imageDesktop;
        }
      };
      setBannerImages(imagesByResolution() || []);
    }
  }, [banners]);

  function handleSelectCategory(category: string) {
    setRoute(category);
    navigate(`/classifieds/category/${category}`);
  }

  return (
    <MainLayout type="classifieds">
      <Container>
        <BannerAndCategories
          category={route}
          onSelectCategory={handleSelectCategory}
          handleType={setTypeCategory}
          background={"linear-gradient(180deg, #08A791 0%, #016D52 100%)"}
          type={t("classifieds")}
          data={categories}
          images={bannerImages} 
        />
        <Content>
          <ContentClassifieds
            suggestedClassifieds={suggestedClassifiedsData}
            realStateClassifieds={RealEstateClassifiedsData}
            vehicleClassifieds={VehicleClassifiedsData}
            employmentClassifieds={EmploymentClassifiedsData}
            techClassifieds={TechClassifiedsData}
            constructionClassifieds={ConstructionClassifiedsData}
            homeClassifieds={HomeClassifiedsData}
            petsClassifieds={PetsClassifiedsData}
            musicClassifieds={MusicClassifiedsData}
            sportsClassifieds={SportsClassifiedsData}
          />
        </Content>
        {isAuth ? (
          <Link to={MAIN_ROUTES.ClassifiedNew}>
            <FloatButton />
          </Link>
        ) : (
          <FloatButton
            onClick={() => showWarning(t("login_to_create_your_classified"))}
          />
        )}
      </Container>
    </MainLayout>
  );
};

export default Classifieds;
