import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useGetFirestoreCollection } from 'hooks/useGetCollectionFirestore/useGetCollectionFirestore';
import { useGetFirestoreCollectionQuery } from "hooks/useGetCollectionFirestore/useGetCollectionFirestore";
import MainLayout from "views/layout/MainLayout";
import { BackButton } from "ui/Buttons";
import FloatButton from "components/float-button";
import ClassifiedCard from "components/cards/Classifieds/Classifieds";
import SideComponentClassifieds from "./components/side-banner";
import {
  Container,
  Content,
  LeftSideContainer,
  RightSideContainer,
  Grid,
} from "./styled";
import { getCountry } from "redux/common";
import { userInfo } from "redux/user";
import { ClassifiedsRoutes } from "constants/classifieds";
import { H2 } from "ui/Text";
import { MAIN_ROUTES } from "constants/routes";

// Main Screen
const MyClassifieds = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const country = useSelector(getCountry);
  const userData: any = useSelector(userInfo as any);
  const userUid: string = userData.uid;

  const [choiceSelected, setChoiceSelected] = useState("list-classifieds");
  // const [showAlertClassifiedDeleted, setAlertClassifiedDeleted] = useState(false);

  // Reemplazamos el hook anterior por useGetFirestoreCollectionQuery
  const { collectionData: classifiedsData = [], refetchCollectionData  } =
    useGetFirestoreCollectionQuery({
      path: `${country}/${ClassifiedsRoutes.Posts}`,
      filters: [{ field: "userId", operator: "==", value: userUid }],
      enabled: !!userUid, // La query se ejecuta cuando tenemos userUid
    });

    function refreshData() {
      refetchCollectionData();
    }


  useEffect(() => {
    if (choiceSelected === "chats-classifieds") {
      navigate(MAIN_ROUTES.ChatClassifieds);
    }
    // eslint-disable-next-line
  }, [choiceSelected]);

  return (
    <MainLayout type="classifieds">
      <Container>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
        {userUid && (
          <Link to={MAIN_ROUTES.ClassifiedNew}>
            <FloatButton />
          </Link>
        )}
        <Content>
          <LeftSideContainer>
            <SideComponentClassifieds
              value={choiceSelected}
              onChange={setChoiceSelected}
            />
          </LeftSideContainer>
          <RightSideContainer>
            {
              //clasificados
              choiceSelected === "list-classifieds" && (
                <>
                  <H2 style={{ marginBottom: "1rem" }}>
                    {t("my_classifieds")}
                  </H2>
                  <Grid>
                    {classifiedsData?.length ? (
                      classifiedsData.map((item: any, index: number) => (
                        <ClassifiedCard
                          data={item}
                          key={index}
                          isOwner={true}
                          onSuccessfulDelete={refreshData}
                        />
                      ))
                    ) : (
                      <h5>{t("there_are_no_classifieds")}</h5>
                    )}
                  </Grid>
                </>
              )
            }
            {choiceSelected === "saved-classifieds" && (
              <>
                <H2 style={{ marginBottom: "1rem" }}>
                  {t("saved_classifieds")}
                </H2>
                <Grid>
                  <h3>{t("there_are_no_saved_classifieds")}</h3>
                </Grid>
              </>
            )}
            {choiceSelected === "chats-classifieds" && (
              <>
                <H2 style={{ marginBottom: "1rem" }}>{t("chats")}</H2>
              </>
            )}
          </RightSideContainer>
        </Content>
      </Container>
    </MainLayout>
  );
};

export default MyClassifieds;
